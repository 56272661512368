import './App.css';
import logo from './assets/images/logo/logo.png';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Swal from 'sweetalert2';
import axios from "axios";
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Login = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {

            await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
                password: password,
                username: username,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then((response => {

                localStorage.setItem('token', response.data.token);

                Swal.fire({
                    title: "Bem vindo",
                    text: '',
                    icon: "success",
                    confirmButtonText: 'ok'
                });
                navigate('/indicacao'); // Redireciona para o dashboard após login bem-sucedido
            }));
        } catch (error) {
            Swal.fire({
                title: "Atenção",
                text: error.response.data,
                icon: "error",
                confirmButtonText: "Fechar"
            });
        }

    };

    return <div className='container d-flex justify-content-center align-items-center min-vh-100 mr-2 pr-2'>
        <div className='col-md-6 content-center'>
            <div className="max-w-sm rounded overflow-hidden shadow-lg pr-4 pl-4">
                <div className="px-6 py-4 bg-white">
                    <div className="p-2 ">
                        <div className="font-bold mb-2">
                            <img src={logo} className="mx-auto d-block logo" alt="..." />
                            <h4 className='title text-center'>Insira seu login </h4>
                        </div>
                        <form encType="application/json" onSubmit={handleSubmit} method="post">
                            <Container>
                                <Row>
                                    <Col sm={12}>
                                        <input placeholder='Login' className='form-control w-full mt-1 rounded-pill' name="username" value={username} type="text" id="username" onChange={(e) => setUsername(e.target.value)} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <input placeholder='Senha' className='form-control w-full mt-2 rounded-pill' name='password' value={password} type='password' id='password' onChange={(e) => setPassword(e.target.value)} />
                                    </Col>
                                    <Col sm={12}>
                                        <div className='text-center mt-2'>
                                            <button id="btn-importar" type='submit' className='btn btn-primary mr-1 pr-1 mb-2 mt-1 rounded-pill w-100'>
                                                Acessar
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <hr className='mt-4 mr-6 ml-6'></hr>
                                </Row>
                                <p style={{ fontSize: "13px" }} className=''>
                                    - Apenas pessoas autorizadas.
                                </p>
                            </Container>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

}

export default Login;