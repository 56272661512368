import './App.css';
import axios from "axios";
import Swal from 'sweetalert2';
import logo from './assets/images/logo/logo.png';
import Informacoes from './informacoes';
import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import InputMask from 'react-input-mask';

function Credenciamento() {
    const [evento, setEvento] = useState([]);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/dados/evento`)
            .then(response => {
                setEvento(response.data[0]);
            });
    }, []);

    const checarCredencial = async (values) => {
        try {
            const result = await Swal.fire({
                title: 'Credenciamento',
                text: "Confirmar credenciamento?",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, imprimir',
                cancelButtonText: 'Cancelar'
            });

            if (result.isConfirmed) {
                const cpf = document.getElementById('cpf').value;

                // Fazer requisição para pegar dados do participante, nome e cooperativa
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/dados/participante/${cpf}`);

                if (!response.data[0]) {
                    await Swal.fire({
                        title: 'CPF não encontrado!',
                        icon: 'error',
                        confirmButtonText: 'Fechar'
                    });
                } else {
                    // Chamar rota de salvar credenciamento no banco
                    await axios.get(`${process.env.REACT_APP_API_URL}/participante/credenciar/${cpf}`);
                    // console.log(credenciarResponse);
                    imprimirCredencial(response.data[0]?.nome, response.data[0]?.cooperativa);

                }
            }
        } catch (error) {
            Swal.fire({
                title: 'Erro na requisição!',
                text: error.message,
                icon: 'error',
                confirmButtonText: 'Fechar'
            });
        }
    };

    function getPrimeiroEUltimoNome(nomeCompleto) {

        const nomes = nomeCompleto.split(' ');
        const primeiroNome = nomes[0];
        const ultimoNome = nomes.pop();

        return `${primeiroNome} ${ultimoNome}`;
    }

    const imprimirCredencial = (value1, value2) => {
        const printContents = document.getElementById('printArea').innerHTML;
        const originalContents = document.body.innerHTML;


        try {
            document.body.innerHTML = `<div class="print-area">${printContents}</div>`;
            document.getElementById('nome').innerText = getPrimeiroEUltimoNome(value1);
            document.getElementById('cooperativa').innerText = value2;
            window.print();
        } finally {
            document.body.innerHTML = originalContents;
            window.location.reload();
        }
    }

    return new Date() > new Date(evento.data_limite_credenciamento) ? (
        <Informacoes />
    ) : (
        <div className='container d-flex justify-content-center align-items-center min-vh-100 mr-2 pr-2'>
            <div className='col-md-6 content-center'>
                <div className="max-w-sm rounded overflow-hidden shadow-lg pr-4 pl-4">
                    <div className="px-6 py-4 bg-white">
                        <div className="p-2 ">
                            <div className="font-bold mb-2">
                                <img src={logo} className="mx-auto d-block logo" alt="..." />
                                <h4 className='title text-center'>Informe o seu Cpf</h4>
                            </div>

                            <Container>
                                <Row>
                                    <Col xs={12} sm={8} className='pr-0 pl-0 ml-2 mr-2'>
                                        <InputMask className='form-control mt-2 rounded-pill text-center w-100' name='cpf' type='text' id='cpf' mask="999.999.999-99" />
                                    </Col>
                                    <Col xs={12} sm={4} className='pl-2'>
                                        <div>
                                            <Button className='rounded-pill mt-2 w-100' onClick={checarCredencial} variant="primary">Confirmar</Button>
                                            
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <hr className='mt-4 mr-6 ml-6'></hr>
                                    <p style={{ fontSize: "13px" }}>
                                        - Após a confirmação faça a impressão da sua credencial selecionando a impressora especificada.
                                    </p>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ textAlign: 'center', alignItems: 'center', alignContent: 'center' }} hidden id="printArea" className="print-area">
                <p style={{ fontSize: "24px", marginBottom: '0px' }} className='centralizado' id='nome'></p>
                <p style={{ fontSize: "24px", marginTop: '0px' }} className='centralizado' id='cooperativa'></p>
            </div>

        </div>
    );
}
export default Credenciamento;