import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import Exportar from './exportar'; // Importe o componente da nova página
import Principal from './principal'; // Importe o componente da nova página
import Importar from './importar'; // Importe o componente da nova página
import Credenciamento from './credenciamento'; // Importe o componente da nova página
import Indicacao from './indicacao';
import Login from './login';
import ProtectedRoute from './protectedRoute';


const RoutesConfig = () => (
  <Router>
    <Routes>
      <Route exact path="/" element={<Principal />} />
      <Route path="/exportar" element={<Exportar />} />
      <Route path="/inscricao" element={<App />} />
      <Route path="/importar" element={<Importar />} />
      <Route path="/credenciamento" element={<Credenciamento />} />
      <Route path="/login" element={<Login />} />
      <Route path="/indicacao" element={<ProtectedRoute component={Indicacao} />} />
    </Routes>
  </Router>
);

export default RoutesConfig;