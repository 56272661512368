import './App.css';
import logo from './assets/images/logo/logo.png';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import Toast from 'react-bootstrap/Toast';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import api from './axiosConfig';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import InputMask from 'react-input-mask';
import Form from 'react-bootstrap/Form';

function Indicacao() {

    const navigate = useNavigate();
    const [user, setUser] = useState(null);

    const [formEstado, setFormEstado] = useState(false);
    const [campanhas, setCampanhas] = useState([]);
    const [campanhasUsuarios, setCampanhasUsuarios] = useState([]);
    const [dados, setDados] = useState({});
    const [show, setShow] = useState(false);
    const [showColar, setShowColar] = useState(false);
    const [showButtonColar, setShowButtonColar] = useState(true);
    const [formValues, setFormValues] = useState({});

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decoded = jwtDecode(token);
            setUser(decoded);
        }
    }, []);

    useEffect(() => {
        const fetchCampanhas = async () => {
            try {
                const campanhasResponse = await api.get(`${process.env.REACT_APP_API_URL}/dados/campanhas`);
                const campanhasUsuariosResponse = await api.get(`${process.env.REACT_APP_API_URL}/dados/campanhas/usuario/${user?.id}`);

                setCampanhas(campanhasResponse.data);
                setCampanhasUsuarios(campanhasUsuariosResponse.data);
            } catch (error) {
                console.error('Erro ao buscar dados:', error);
            }
        };

        fetchCampanhas();
    }, [user?.id]);


    function verificaEstadoForm(id) {
        return campanhasUsuarios.some(objeto => objeto.campanha_id === id);
    }

    function getDadosCampanhaUsuario(id) {
        return campanhasUsuarios.find(objeto => objeto.campanha_id === id) || false;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        // Lógica para enviar os dados do formulário para o backend

        const id = event.target.id.split('-')[1];

        const patrono = document.getElementById('patrono' + id).value;
        const telefone_patrono = document.getElementById('telefone_patrono' + id).value;
        const cargo_patrono = document.getElementById('cargo_patrono' + id).value;
        const nome_indicado = document.getElementById('nome_indicado' + id).value;
        const telefone_indicado = document.getElementById('telefone_indicado' + id).value;
        const cargo_indicado = document.getElementById('cargo_indicado' + id).value;
        const email_indicado = document.getElementById('email_indicado' + id).value;
        const pa_indicado = document.getElementById('pa_indicado' + id).value;
        const convidado01 = document.getElementById('convidado01' + id).value;
        const telefone_convidado01 = document.getElementById('telefone_convidado01' + id).value;
        const cargo_convidado01 = document.getElementById('cargo_convidado01' + id).value;
        const convidado02 = document.getElementById('convidado02' + id).value;
        const telefone_convidado02 = document.getElementById('telefone_convidado02' + id).value;
        const cargo_convidado02 = document.getElementById('cargo_convidado02' + id).value;

        await api.post(`${process.env.REACT_APP_API_URL}/dados/indicacao`, {
            cooperativa_id: parseInt(user.cooperativa),
            campanha_id: parseInt(id),
            usuario_id: parseInt(user.id),
            patrono: patrono,
            telefone_patrono: telefone_patrono,
            cargo_patrono: cargo_patrono,
            nome_indicado: nome_indicado,
            telefone_indicado: telefone_indicado,
            cargo_indicado: cargo_indicado,
            email_indicado: email_indicado,
            pa_indicado: pa_indicado,
            convidado01: convidado01,
            telefone_convidado01: telefone_convidado01,
            cargo_convidado01: cargo_convidado01,
            convidado02: convidado02,
            telefone_convidado02: telefone_convidado02,
            cargo_convidado02: cargo_convidado02
        })
            .then((response => {
                Swal.fire({
                    title: response.data.title,
                    text: response.data.text,
                    icon: response.data.icon,
                    confirmButtonText: response.data.confirmButtonText,
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                });
                if (response.data.icon == 'success') {
                    setFormEstado(true);
                }
            }));
    };



    function copiar(id) {
        //Copiar os valores de todos os campos
        setShowButtonColar(false);
        setShow(true);

        const patrono = document.getElementById('patrono' + id).value;
        const telefone_patrono = document.getElementById('telefone_patrono' + id).value;
        const cargo_patrono = document.getElementById('cargo_patrono' + id).value;
        const nome_indicado = document.getElementById('nome_indicado' + id).value;
        const telefone_indicado = document.getElementById('telefone_indicado' + id).value;
        const cargo_indicado = document.getElementById('cargo_indicado' + id).value;
        const email_indicado = document.getElementById('email_indicado' + id).value;
        const pa_indicado = document.getElementById('pa_indicado' + id).value;
        const convidado01 = document.getElementById('convidado01' + id).value;
        const telefone_convidado01 = document.getElementById('telefone_convidado01' + id).value;
        const cargo_convidado01 = document.getElementById('cargo_convidado01' + id).value;
        const convidado02 = document.getElementById('convidado02' + id).value;
        const telefone_convidado02 = document.getElementById('telefone_convidado02' + id).value;
        const cargo_convidado02 = document.getElementById('cargo_convidado02' + id).value;

        const dadosIndicado = {
            'patrono': patrono,
            'telefone_patrono': telefone_patrono,
            'cargo_patrono': cargo_patrono,
            'nome_indicado': nome_indicado,
            'telefone_indicado': telefone_indicado,
            'cargo_indicado': cargo_indicado,
            'email_indicado': email_indicado,
            'pa_indicado': pa_indicado,
            'convidado01': convidado01,
            'telefone_convidado01': telefone_convidado01,
            'cargo_convidado01': cargo_convidado01,
            'convidado02': convidado02,
            'telefone_convidado02': telefone_convidado02,
            'cargo_convidado02': cargo_convidado02
        };
        setDados([dadosIndicado]);

    }

    const formatarTelefone = (telefone) => {
        // Remove todos os caracteres não numéricos
        const telefoneLimpo = telefone.toString().replace(/\D/g, "");
        // Formata o telefone conforme necessário (exemplo: (XX) XXXXX-XXXX)
        return telefoneLimpo.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    };

    function colar(index) {
        //Atribuir os valores de todos os campos para o novo formulário

        setShowColar(true);

        const novosValores = {
            patrono: dados[0].patrono,
            telefone_patrono: formatarTelefone(dados[0].telefone_patrono),
            cargo_patrono: dados[0].cargo_patrono,
            nome_indicado: dados[0].nome_indicado,
            telefone_indicado: formatarTelefone(dados[0].telefone_indicado),
            cargo_indicado: dados[0].cargo_indicado,
            email_indicado: dados[0].email_indicado,
            pa_indicado: dados[0].pa_indicado,
            convidado01: dados[0].convidado01,
            telefone_convidado01: formatarTelefone(dados[0].telefone_convidado01),
            cargo_convidado01: dados[0].cargo_convidado01,
            convidado02: dados[0].convidado02,
            telefone_convidado02: formatarTelefone(dados[0].telefone_convidado02),
            cargo_convidado02: dados[0].cargo_convidado02
        };

        setFormValues(prevValues => ({
            ...prevValues,
            [index]: novosValores
        }));

    }

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login');
    };

    return (<div className='container d-flex justify-content-center align-items-center min-vh-100 mr-2 pr-2'>
        <div className='col-md-12 content-center'>
            <div className="max-w-sm rounded overflow-hidden shadow-lg pr-4 pl-4">
                <div className="px-6 py-4 bg-white">
                    <div className="p-2 ">
                        <div className="font-bold mb-2">
                            <img src={logo} className="mx-auto d-block logo" alt="..." />
                        </div>
                        <div>
                            <h4 className='text-center cor-verde-01'>{user?.nome}</h4> <button className='btn btn-secondary rounded-pill' onClick={handleLogout}>Sair <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-left" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0z" />
                                <path fill-rule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708z" />
                            </svg></button>
                        </div>

                        <h4 className='title text-center cor-verde-02'>Indicações</h4>
                        <Accordion defaultActiveKey={0}>
                            {campanhas.map((campanha, index) => (
                                <Accordion.Item className='custom-accordion-item' eventKey={campanha.id}>
                                    <Accordion.Header className='custom-header title custom-accordion-header'> <svg hidden={!verificaEstadoForm(campanha.id) == true} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                        <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                    </svg> <svg hidden={!verificaEstadoForm(campanha.id) == false} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-lg" viewBox="0 0 16 16">
                                            <path d="M7.005 3.1a1 1 0 1 1 1.99 0l-.388 6.35a.61.61 0 0 1-1.214 0zM7 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0" />
                                        </svg>  <span className='m-1'>{campanha.nome} </span></Accordion.Header>
                                    <Accordion.Body className='custom-accordion-body'>
                                        <form onSubmit={handleSubmit} id={'form-' + campanha.id} encType="multipart/form-data" method="post">
                                            <Container>
                                                <Row className='m-2'>
                                                    <Row className='bg-success-subtle rounded pt-3  pb-4'>
                                                        <Col sm={5}>
                                                            <Form.Group controlId={'patrono' + campanha.id}>
                                                                <Form.Label style={{ marginLeft: '10px' }} className='fs-6 mt-1 mb-0'>Presidente ou Diretor da Cooperativa *</Form.Label>
                                                                <Form.Control required disabled={verificaEstadoForm(campanha.id) == true} placeholder='' className='form-control w-full mt-0 rounded-pill' value={!verificaEstadoForm(campanha.id) ? formValues[campanha.id]?.patrono || '' : getDadosCampanhaUsuario(campanha.id).patrono || ''} name={'patrono'} type="text" onChange={(e) => {
                                                                    const updatedValues = { ...formValues };
                                                                    updatedValues[campanha.id] = { ...updatedValues[campanha.id], patrono: e.target.value };
                                                                    setFormValues(updatedValues);
                                                                }} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Form.Group controlId={'telefone_patrono' + campanha.id}>
                                                                <Form.Label style={{ marginLeft: '10px' }} className='fs-6 mt-1 mb-0'>Telefone *</Form.Label>
                                                                <Form.Control as={InputMask} mask="(99) 99999-9999" required disabled={verificaEstadoForm(campanha.id) == true} placeholder='' className='form-control w-full mt-0 rounded-pill' value={!verificaEstadoForm(campanha.id) ? formValues[campanha.id]?.telefone_patrono : getDadosCampanhaUsuario(campanha.id).telefone_patrono || ''} name={'telefone_patrono'} type="text" onChange={(e) => {
                                                                    const updatedValues = { ...formValues };
                                                                    updatedValues[campanha.id] = { ...updatedValues[campanha.id], telefone_patrono: e.target.value };
                                                                    setFormValues(updatedValues);
                                                                }} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <Form.Group controlId={'cargo_patrono' + campanha.id}>
                                                                <Form.Label style={{ marginLeft: '10px' }} className='fs-6 mt-1 mb-0'> Cargo *</Form.Label>
                                                                <Form.Control required disabled={verificaEstadoForm(campanha.id) == true} placeholder='' className='form-control w-full mt-0 rounded-pill' value={!verificaEstadoForm(campanha.id) ? formValues[campanha.id]?.cargo_patrono : getDadosCampanhaUsuario(campanha.id).cargo_patrono || ''} name={'cargo_patrono'} type="text" onChange={(e) => {
                                                                    const updatedValues = { ...formValues };
                                                                    updatedValues[campanha.id] = { ...updatedValues[campanha.id], cargo_patrono: e.target.value };
                                                                    setFormValues(updatedValues);
                                                                }} />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row className='bg-success-subtle rounded pt-3 mt-2 pb-4'>
                                                        <Col sm={5}>
                                                            <Form.Group controlId={'nome_indicado' + campanha.id}>
                                                                <Form.Label style={{ marginLeft: '10px' }} className='fs-6 mt-1 mb-0'> Nome do indicado *</Form.Label>
                                                                <Form.Control required disabled={verificaEstadoForm(campanha.id) == true} placeholder='' className='form-control w-full mt-0 rounded-pill' value={!verificaEstadoForm(campanha.id) ? formValues[campanha.id]?.nome_indicado : getDadosCampanhaUsuario(campanha.id).nome_indicado || ''} name={'nome_indicado'} type="text" onChange={(e) => {
                                                                    const updatedValues = { ...formValues };
                                                                    updatedValues[campanha.id] = { ...updatedValues[campanha.id], nome_indicado: e.target.value };
                                                                    setFormValues(updatedValues);
                                                                }} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Form.Group controlId={'telefone_indicado' + campanha.id}>
                                                                <Form.Label style={{ marginLeft: '10px' }} className='fs-6 mt-1 mb-0'> Telefone *</Form.Label>
                                                                <Form.Control controlId={'telefone_indicado' + campanha.id} as={InputMask} mask="(99) 99999-9999" required disabled={verificaEstadoForm(campanha.id) == true} placeholder='' className='form-control w-full mt-0 rounded-pill' value={!verificaEstadoForm(campanha.id) ? formValues[campanha.id]?.telefone_indicado : getDadosCampanhaUsuario(campanha.id).telefone_indicado || ''} name={'telefone_indicado'} type="text" onChange={(e) => {
                                                                    const updatedValues = { ...formValues };
                                                                    updatedValues[campanha.id] = { ...updatedValues[campanha.id], telefone_indicado: e.target.value };
                                                                    setFormValues(updatedValues);
                                                                }} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <Form.Group controlId={'cargo_indicado' + campanha.id}>
                                                                <Form.Label style={{ marginLeft: '10px' }} className='fs-6 mt-1 mb-0'> Cargo *</Form.Label>
                                                                <Form.Control required disabled={verificaEstadoForm(campanha.id) == true} placeholder='' className='form-control w-full mt-0 rounded-pill' value={!verificaEstadoForm(campanha.id) ? formValues[campanha.id]?.cargo_indicado || '' : getDadosCampanhaUsuario(campanha.id).cargo_indicado || ''} name={'cargo_indicado'} type="text" onChange={(e) => {
                                                                    const updatedValues = { ...formValues };
                                                                    updatedValues[campanha.id] = { ...updatedValues[campanha.id], cargo_indicado: e.target.value };
                                                                    setFormValues(updatedValues);
                                                                }} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={5}>
                                                            <Form.Group controlId={'email_indicado' + campanha.id}>
                                                                <Form.Label style={{ marginLeft: '10px' }} className='fs-6 mt-1 mb-0'> Email *</Form.Label>
                                                                <Form.Control required disabled={verificaEstadoForm(campanha.id) == true} placeholder='' className='form-control w-full mt-0 rounded-pill' value={!verificaEstadoForm(campanha.id) ? formValues[campanha.id]?.email_indicado || '' : getDadosCampanhaUsuario(campanha.id).email_indicado || ''} name={'email_indicado'} type='text' onChange={(e) => {
                                                                    const updatedValues = { ...formValues };
                                                                    updatedValues[campanha.id] = { ...updatedValues[campanha.id], email_indicado: e.target.value };
                                                                    setFormValues(updatedValues);
                                                                }} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <Form.Group controlId={'pa_indicado' + campanha.id}>
                                                                <Form.Label style={{ marginLeft: '10px' }} className='fs-6 mt-1 mb-0'> PA</Form.Label>
                                                                <Form.Control disabled={verificaEstadoForm(campanha.id) == true} placeholder='' className='form-control w-full mt-0 rounded-pill' value={!verificaEstadoForm(campanha.id) ? formValues[campanha.id]?.pa_indicado : getDadosCampanhaUsuario(campanha.id).pa_indicado || ''} name={'pa_indicado'} type="text" onChange={(e) => {
                                                                    const updatedValues = { ...formValues };
                                                                    updatedValues[campanha.id] = { ...updatedValues[campanha.id], pa_indicado: e.target.value };
                                                                    setFormValues(updatedValues);
                                                                }} />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row className='bg-success-subtle rounded pt-3 mt-2 pb-4'>
                                                        <Col sm={5}>
                                                            <Form.Group controlId={'convidado01' + campanha.id}>
                                                                <Form.Label style={{ marginLeft: '10px' }} className='fs-6 mt-1 mb-0'> Nome do primeiro convidado</Form.Label>
                                                                <Form.Control disabled={verificaEstadoForm(campanha.id) == true} placeholder='' className='form-control w-full mt-0 rounded-pill' value={!verificaEstadoForm(campanha.id) ? formValues[campanha.id]?.convidado01 : getDadosCampanhaUsuario(campanha.id).convidado01 || ''} name={'convidado01'} type="text" onChange={(e) => {
                                                                    const updatedValues = { ...formValues };
                                                                    updatedValues[campanha.id] = { ...updatedValues[campanha.id], convidado01: e.target.value };
                                                                    setFormValues(updatedValues);
                                                                }} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Form.Group controlId={'telefone_convidado01' + campanha.id}>
                                                                <Form.Label style={{ marginLeft: '10px' }} className='fs-6 mt-1 mb-0'> Telefone</Form.Label>
                                                                <Form.Control as={InputMask} mask="(99) 99999-9999" disabled={verificaEstadoForm(campanha.id) == true} placeholder='' className='form-control w-full mt-0 rounded-pill' value={!verificaEstadoForm(campanha.id) ? formValues[campanha.id]?.telefone_convidado01 : getDadosCampanhaUsuario(campanha.id).telefone_convidado01 || ''} name={'telefone_convidado01'} type="text" onChange={(e) => {
                                                                    const updatedValues = { ...formValues };
                                                                    updatedValues[campanha.id] = { ...updatedValues[campanha.id], telefone_convidado01: e.target.value };
                                                                    setFormValues(updatedValues);
                                                                }} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <Form.Group controlId={'cargo_convidado01' + campanha.id}>
                                                                <Form.Label style={{ marginLeft: '10px' }} className='fs-6 mt-1 mb-0'> Cargo</Form.Label>
                                                                <Form.Control disabled={verificaEstadoForm(campanha.id) == true} placeholder='' className='form-control w-full mt-0 rounded-pill' value={!verificaEstadoForm(campanha.id) ? formValues[campanha.id]?.cargo_convidado01 : getDadosCampanhaUsuario(campanha.id).cargo_convidado01 || ''} name={'cargo_convidado01'} type="text" onChange={(e) => {
                                                                    const updatedValues = { ...formValues };
                                                                    updatedValues[campanha.id] = { ...updatedValues[campanha.id], cargo_convidado01: e.target.value };
                                                                    setFormValues(updatedValues);
                                                                }} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={5}>
                                                            <Form.Group controlId={'convidado02' + campanha.id}>
                                                                <Form.Label style={{ marginLeft: '10px' }} className='fs-6 mt-1 mb-0'> Nome do segundo convidado</Form.Label>
                                                                <Form.Control disabled={verificaEstadoForm(campanha.id) == true} placeholder='' className='form-control w-full mt-0 rounded-pill' value={!verificaEstadoForm(campanha.id) ? formValues[campanha.id]?.convidado02 : getDadosCampanhaUsuario(campanha.id).convidado02 || ''} name={'convidado02'} type="text" onChange={(e) => {
                                                                    const updatedValues = { ...formValues };
                                                                    updatedValues[campanha.id] = { ...updatedValues[campanha.id], convidado02: e.target.value };
                                                                    setFormValues(updatedValues);
                                                                }} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Form.Group controlId={'telefone_convidado02' + campanha.id}>
                                                                <Form.Label style={{ marginLeft: '10px' }} className='fs-6 mt-1 mb-0'> Telefone</Form.Label>
                                                                <Form.Control as={InputMask} mask="(99) 99999-9999" disabled={verificaEstadoForm(campanha.id) == true} placeholder='' className='form-control w-full mt-0 rounded-pill' value={!verificaEstadoForm(campanha.id) ? formValues[campanha.id]?.telefone_convidado02 : getDadosCampanhaUsuario(campanha.id).telefone_convidado02 || ''} name={'telefone_convidado02'} type="text" onChange={(e) => {
                                                                    const updatedValues = { ...formValues };
                                                                    updatedValues[campanha.id] = { ...updatedValues[campanha.id], telefone_convidado02: e.target.value };
                                                                    setFormValues(updatedValues);
                                                                }} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <Form.Group controlId={'cargo_convidado02' + campanha.id}>
                                                                <Form.Label style={{ marginLeft: '10px' }} className='fs-6 mt-1 mb-0'>Cargo</Form.Label>
                                                                <Form.Control disabled={verificaEstadoForm(campanha.id) == true} placeholder='' className='form-control w-full mt-0 rounded-pill' value={!verificaEstadoForm(campanha.id) ? formValues[campanha.id]?.cargo_convidado02 : getDadosCampanhaUsuario(campanha.id).cargo_convidado02 || ''} name={'cargo_convidado02'} type="text" onChange={(e) => {
                                                                    const updatedValues = { ...formValues };
                                                                    updatedValues[campanha.id] = { ...updatedValues[campanha.id], cargo_convidado02: e.target.value };
                                                                    setFormValues(updatedValues);
                                                                }} />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Row>
                                                <Row>
                                                    <Col sm={9}>
                                                        <div className=''>
                                                            <button disabled={verificaEstadoForm(campanha.id) == true && !formEstado} id="btn-importar" type='submit' className='btn botao-confirmacao mr-1 pr-1 mb-2 mt-2 rounded-pill'>
                                                                Finalizar indicação <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
                                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                                    <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <div className='d-flex justify-content-end'>
                                                            <button onClick={() => copiar(`${campanha.id}`)} type='button' className='btn btn-secondary mt-2 rounded-pill m-1'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard" viewBox="0 0 16 16">
                                                                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                                                                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                                                                </svg> Copiar
                                                            </button>

                                                            <button hidden={showButtonColar} onClick={() => colar(`${campanha.id}`)} type='button' className='btn btn-secondary mt-2 rounded-pill m-1'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard-check-fill" viewBox="0 0 16 16">
                                                                    <path d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5z" />
                                                                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5zm6.854 7.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708" />
                                                                </svg> Colar
                                                            </button>
                                                        </div>

                                                    </Col>
                                                    <Col className='d-flex justify-content-end' sm={12}>
                                                        <Toast onClose={() => setShow(false)} show={show} delay={1000} autohide>
                                                            <Toast.Body>Copiado com sucesso!</Toast.Body>
                                                        </Toast>
                                                    </Col>
                                                    <Col className='d-flex justify-content-end' sm={12}>
                                                        <Toast onClose={() => setShowColar(false)} show={showColar} delay={1000} autohide>
                                                            <Toast.Body>Colado com sucesso!</Toast.Body>
                                                        </Toast>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <hr className='mt-4 mr-6 ml-6'></hr>
                                                </Row>
                                            </Container>
                                        </form>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
    </div>



    );
}

export default Indicacao;